import * as React from 'react'
import { Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

function Hero (props) {

	return (

		<div className ="hero">
			<div className="hero-image-container">
				<BackgroundImage fluid={props.fluid} className="hero-image">

						<div className="hero-text">

	          <h1
							className="title has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen is-spaced"
							style={{backgroundColor:'rgb(0,64,123)',
                        	boxShadow: 'rgb(0,64,123) 0px 0px 20px 10px',
							color: 'white',
							lineHeight: '1',
							padding: '0.25rem'}}>
	            {props.title}		
	          </h1>
			  
		        {props.subtitle &&
		          <h3
		            className="subtitle has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
								style={{backgroundColor:'rgb(0,64,123)',
								boxShadow: 'rgb(0,64,123) 0px 0px 20px 10px',
								color: 'white',
								lineHeight: '1',
								padding: '0.25rem'}}>
								{props.subtitle}
		          </h3>
		        }

		        {props.buttonTxt &&
		          <div id="hero-button">
		              <a href={props.buttonLink} className="button is-rounded is-red is-medium">
		                {props.buttonTxt}
		              </a>
		          </div>
		        }
				{props.breadcrumbs &&
		          <div style={{position:'absolute', bottom:0, padding:'0.75rem', backgroundColor:'rgb(0,64,123)'}}>
		              <nav className="breadcrumb has-text-weight-bold" aria-label="breadcrumbs">
						<ul>
				<li><Link to='/' className="has-text-white">PanSurg</Link></li>
					{props.secondcrumb &&
				<li><Link to={props.secondlink} className="has-text-white">{props.secondcrumb}</Link></li>
					}
					{props.thirdcrumb &&
				<li><Link to={props.thirdlink} className="has-text-white">{props.thirdcrumb}</Link></li>
					}
					{props.activecrumb &&
				<li className="is-active"><a aria-current="page" className="has-text-white">{props.activecrumb}</a></li>
					}
						</ul>
						</nav>
		          </div>
		        }
	        </div>	
			

			</BackgroundImage>
			

</div>
	</div>


  )
}

export default Hero
